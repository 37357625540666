<template>
  <div>
    <div v-loading="loggingIn" class="container">
      <div>
        <h1>
          <p>/</p>
          Login
        </h1>
        <el-form :model="form">
          <el-form-item class="input-cont">
            <div>
              <img src="../../assets/icons/email.svg" alt="" />
              <p class="titulo-input">Email</p>
            </div>
            <el-input v-model="form.email" v-on:keyup.enter="login" :input-style="{ width: '500px', height: '40px' }" class="input-log" type="text" />
          </el-form-item>
          <el-form-item class="input-cont">
            <div>
              <img src="../../assets/icons/cadeado.svg" alt="" />
              <p class="titulo-input">Senha</p>
            </div>
            <el-input v-model="form.password" v-on:keyup.enter="login" :input-style="{ width: '500px', height: '40px' }" class="input-log" type="password" />
            <div href="" @click="restorePasswordDialog = true" class="missingPasswordButton">Esqueci a senha</div>
          </el-form-item>
          <el-form-item>
            <el-button @click="login" class="login">Entrar</el-button>
          </el-form-item>
        </el-form>

        <!-- Users shouldn't be able to create accounts -->
        <!-- <button class="criar">criar nova conta</button> -->
      </div>

      <!-- recover password form -->
      <el-dialog v-model="restorePasswordDialog" title="Recuperação de senha" width="600px">
        <p>Por favor, digite o e-mail de sua conta.</p>
        <el-input v-model="form.email" v-on:keyup.enter="restorePassword" :input-style="{ height: '40px' }" class="input-log" style="width: 70%" type="text" />
        <template #footer>
          <el-button type="primary" @click="restorePassword">Confirm</el-button>
        </template>
      </el-dialog>

      <div class="cont-grafismos">
        <img class="graf-1" src="../../assets/grafismo/graf_dm_pink.svg" alt="" />
        <img class="graf-2" src="../../assets/grafismo/graf_dm_blue.svg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";
import { ElMessageBox, ElMessage } from "element-plus";
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  name: "Login",
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      rules: {
        email: [
          {
            required: true,
            message: "Por favor digite o email",
            trigger: "blur",
          },
          {
            type: "email",
            message: "Por favor digite um email válido",
            trigger: ["blur", "change"],
          },
        ],
      },
      error: "",
      loggingIn: false,
      restorePasswordDialog: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["isLoggedIn", "currentUser"]),
  },
  created() {
    // redirect user to painel if already logged in
    console.log("created before")
    if (this.isLoggedIn && this.currentUser.uid) {
      this.$router.push("/itinerary");
    }
    console.log('created after')
  },
  mounted() {
    console.log(`${process.env.VUE_APP_AUTH_ENDPOINT}/auth/status`);
    console.log("here mounted login", process.env.vue)
    axios({
      method: "post",
      url: `${process.env.VUE_APP_AUTH_ENDPOINT}/auth/status`,
      withCredentials: true,
    })
      .then(function (res) {
        console.log("axios res", res);

      })
      .catch((e) => console.log(e));
  },
  methods: {
    async login() {
      var self = this;
      self.loggingIn = true;
      const userFunctions = require("../../helpers/functions/users").default;
      firebase
        .auth()
        .signInWithEmailAndPassword(self.form.email, self.form.password)
        .then(async (user) => {
          const userData = (await new userFunctions().getUser({ uid: user.uid })).data;
          console.log(user);

          await self.$store.dispatch("auth/login", {
            ...user.user,
            ...userData,
          });
          self.$router.push(`/itinerary`);
        })
        .catch((err) => {
          console.log(err);
          self.loggingIn = false;
          ElMessageBox.alert(
            'E-mail e/ou senha inválido(s).<br/>Caso continue com esse problema, clique em "Recuperar Senha"',
            "Não foi possível fazer o login",
            {
              confirmButtonText: "OK",
              customStyle: "font-family: Avenir, Helvetica, Arial, sans-serif;",
              dangerouslyUseHTMLString: true,
            }
          );
        });
    },
    restorePassword() {
      firebase
        .auth()
        .sendPasswordResetEmail(this.form.email)
        .then(() => {
          ElMessage({
            message: "O e-mail com as instruções para recuperação<br/>de senha foi enviado corretamente",
            type: "success",
            dangerouslyUseHTMLString: true,
          });
          this.restorePasswordDialog = false;
        })
        .catch(function (err) {
          ElMessageBox.alert(
            "Parece que você forneceu um e-mail inválido ou que não está cadastrado.<br/>Caso acredite que tenha digitado o e-mail correto, contate o administrador do painel.",
            "Não foi possível recuperar a senha",
            {
              confirmButtonText: "OK",
              customStyle: "font-family: Avenir, Helvetica, Arial, sans-serif;",
              dangerouslyUseHTMLString: true,
            }
          );
        });
    },
  },
};
</script>

<style scoped>
body {
  position: relative;
  margin: 0;
  padding: 0;
}
.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  flex-direction: column;
  width: calc(100vw - 20%);
  max-width: 1100px;
  gap: 70px;
  margin: auto;
  /* margin-bottom: 90px; */
}

h1 {
  margin: 0;
  display: flex;
  align-items: center;
  font-size: 50px;
  color: black;
}
h1 p {
  color: #0567ff;
}
.input-cont {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.input-cont div {
  display: flex;
}

.input-cont img {
  width: 20px;
  margin-right: 10px;
}

.titulo-input {
  font-size: 14px;
}

.input-log {
  width: calc(100% - 15px);
  height: 40px;
  margin-bottom: 15px;
}

.login {
  border: none;
  border-radius: 100px;
  background: #0567ff;
  color: white;
  font-size: 20px;
  width: 130px;
  height: 35px;
  transition: 0.3s;
  cursor: pointer;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 30px;
}
.login:hover {
  background: #0047b3;
  transition: 0.2s;
}

hr {
  margin-bottom: 30px;
}

.criar {
  background: #46c319;
  color: white;
  border: none;
  border-radius: 7px;
  font-size: 14px;
  width: 125px;
  height: 45px;
  margin-bottom: 30px;
  transition: 0.3s;
  cursor: pointer;
}

.criar:hover {
  background: #2e9509;
  transition: 0.2s;
}

.missingPasswordButton {
  cursor: pointer;
  text-decoration: underline;
  font-size: 14px;
}

.messageBox {
  font-family: Avenir, Helvetica, Arial, sans-serif;
}

/* */

.cont-grafismos {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
}

.graf-2,
.graf-1 {
  width: 200px;
}

.graf-1 {
  margin-top: 150px;
}

.graf-2 {
  margin-bottom: 150px;
}

/* */

footer {
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #2a2a2a;
  color: white;
  width: 100%;
  height: 80px;
}

footer p {
  margin-right: 30px;
}

footer img {
  height: 20px;
  margin-left: 30px;
}
</style>
