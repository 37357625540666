import { createRouter, createWebHistory } from "vue-router";

// Firebase
import firebase from "firebase";

import Store from "@/store";

import About from "../views/About.vue";

import EmptyRouterView from "../views/EmptyRouterView.vue";
import Login from "../views/Account/Login.vue";
import Itineraries from "../views/Itineraries/Itineraries.vue";
import Itinerary from "@/views/Itineraries/Itinerary.vue";

const routes = [
  {
    path: "",
    components: {
      default: EmptyRouterView,
    },
    children: [
      {
        path: "",
        name: "Painel",
        component: Login,
      },
      {
        path: "login",
        name: "Login",
        components: Login,
      },
    ],
  },
  {
    path: "/about",
    name: "About",
    component: About,
  },
  {
    path: "/itinerary",
    name: "Itineraries",
    props: true,
    component: EmptyRouterView,
    children: [
      {
        path: "",
        name: "ItineraryList",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: Itineraries,
      },
      {
        path: ":id",
        name: "Itinerary",
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: Itinerary,
      },
    ],
  },
  {
    /* Wildcard path to catch other paths */
    path:  "/:catchAll(.*)",
    name: "notfound",
    component: () => import("../views/NotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Navigation guards
router.beforeEach((to, from, next) => {
  const isLoggedIn = Store.getters["auth/isLoggedIn"];
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  console.log(`router: isLoggedIn: ${isLoggedIn}`)

  if (requiresAuth && !isLoggedIn) {
    // requires login, send to login page
    firebase
      .auth()
      .signOut()
      .then(() => {
        console.log('trying to redirect')
        next({route:"/login"});
      });
  } else if (!requiresAuth && isLoggedIn) {
    // allowed to go through
    next();
  } else {
    // allowed to go through
    next();
  }
});

export default router;
