import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import vuex from "vuex";
import elementPlus from "element-plus";
import "element-plus/dist/index.css";
import ptBr from "element-plus/es/locale/lang/pt-br";

import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import axios from "axios";

// Other imports goes here
import firebase from "./helpers/firebase";
import userFunctions from "./helpers/functions/users";

let app;
app = createApp(App).use(store).use(router).use(vuex).use(elementPlus, { locale: ptBr }).use(ElementPlusIconsVue);
//.mount("#app");

firebase.auth().onAuthStateChanged(async (user) => {
  let userData;
  console.log("on auth state changed", user);
  if (user) userData = (await new userFunctions().getUser({ uid: user.uid })).data; // this promise was slow enough to not resolve before the page loads (even though it's a really fast promise)
  if (user) store.dispatch("auth/login", { ...user, ...userData });
  else store.dispatch("auth/logout");
  // store.commit('authStateChanged', user);
  app.mount("#app");
});

console.log(`${process.env.VUE_APP_AUTH_ENDPOINT}/auth/status`);
axios({
  method: "post",
  url: `${process.env.VUE_APP_AUTH_ENDPOINT}/auth/status`,
  withCredentials: true,
})
  .then(function (res) {
    console.log("axios res", res);
    let idToken = res.data.idToken;
    if (idToken) return firebase.auth().signInWithCustomToken(idToken);
    else {
      if (res.data.redirect) {
        window.location = res.data.redirect;
      } else {
        // window.location = "https://apps.datamachina.com.br";
      }
    }
  })
  .then(function (userCredential) {
    console.log("logged in", userCredential);
    let user = userCredential.user;
    console.log("logged in", user);
    if (user) {
      router.push("/itinerary");
    } else {
      // router.push("/login");
      console.log('shoudl redirect here')
    }
  })
  .catch((e) => {
    // catch error
    console.log(e);
  });
