export const DEBUG = false;

export const MAPBOX_KEY = "pk.eyJ1IjoiZGF0YW1hY2hpbmEiLCJhIjoiY2sxaDZ3bDI2MTRpdTNjbGJka29nNjFwbyJ9.Eef3UvI4IXVSzmQq3Dkdfg";

export const FIREBASE_KEYS = {
  apiKey: "AIzaSyBDW47xMkzV617JCq5lPMyrAgTUM9iE1T0",
  authDomain: "projeto-base-dm.firebaseapp.com",
  projectId: "projeto-base-dm",
  storageBucket: "projeto-base-dm.appspot.com",
  messagingSenderId: "374207671346",
  appId: "1:374207671346:web:40f553c79b448da719e02b",
  measurementId: "G-T31CKTS00L",
};

export const FIREBASE_ORG = "cw4gqK26BVeFReM52XVN";

export const API_URL = "https://apidev.datamachina.com.br";
